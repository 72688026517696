<template>
    <div class="content">
        <div class="tabbarBox">
            <div class="titleBox">
                <div class="title">行业动态</div>
            </div>
        </div>
        <div class="mainListBox">
            <div class="card" v-for="(item, index) in courseList" :key="index">
                <img :src="item.img" alt="">
                <div class="courseName">{{item.title}}</div>
                <div class="courseTips">{{item.tips}}</div>
                
            </div>
        </div>
        <div class="paginationBox">
            <div class="total">共{{ courseList.length }}条</div>
            <el-pagination
                @current-change="handleCurrentChange"
                background
                layout="prev, pager, next, jumper"
                :page-size="15"
                :total="courseTotal">
            </el-pagination>
        </div>
    </div>
</template>

<script>
export default {
    name: "courseCenter",
    data () {
        return {
            firstClassifyIndex: 0,
            courseList:[
                {
                    img:"https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/dynamicImg.jpeg",
                    title:"关于做好2021年度技工院校等统计工作的通知",
                    district:"各省、自治区、直辖市及新疆生产建设兵团人力资源社会保障厅（局）：为切实做好2021年度技工院校、就业训练中心、民办培训机构等统计工作，现就有关事项通知如下：一、关于技工院校等统计年报（一）根据《人力资源社会保障部关于印发人力资源和社会保障统计调查制度的通知》（人社部发〔2021〕100号）有关要求，2021年度统计年报使用《人力资源和社会保障统计调查制度》中的《技工院校综合情况表》（表号：人社统OS1号，见附件1）、《就业训练中心综合情况表》（表号：人社统OS2号，见附件2）、《民办培训机构综合情况表》（表号：人社统OS3号，见附件3），上述报表的报告期均为上年12月1日至本年11月30日，报表结构采用2021年版本，",
                    link:"https://mp.weixin.qq.com/s/1vawrfTb72_8X07lZi_Lig"
                },
                {   
                    img:"https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/dynamicImg2.jpeg",
                    title:"产教综合体：产教融合的创新平台",
                    district:"国务院办公厅《关于深化产教融合的若干意见》明确指出，“应鼓励区域、行业骨干企业联合职业学校、高等学校共同组建产教融合集团（联盟），带动中小企业参与，推进实体化运作。”针对企业参与渠道不通畅的问题，如何通过实体化运作，搭建具有校企平等话语权的平台，增强企业参与动力，是实现产与教真“融”真“合”的关键。",
                    link:"https://mp.weixin.qq.com/s/8QNdgewDMcOlSrQVIOR86A"
                },
                {   
                    img:"https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/dynamicImg3.jpeg",
                    title:"2021年版《国家职业资格目录》出炉，目录全文+权威解读，大变化！",
                    district:"2021年版国家职业资格目录共计72项职业资格。其中，专业技术人员职业资格59项，含准入类33项，水平评价类26项技能人员职业资格13项。与2017年版相比，《目录》有哪些变化？",
                    link:"https://mp.weixin.qq.com/s/egZ6BmwcuJFnYbd4Pb1Zig"
                },
                {   
                    img:"https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/dynamicImg4.jpeg",
                    title:"发改委关于推动生活性服务业补短板上水平提高人民生活品质若干意见的通知",
                    district:"各省、自治区、直辖市人民政府，国务院各部委、各直属机构：国家发展改革委《关于推动生活性服务业补短板上水平提高人民生活品质的若干意见》已经国务院同意，现转发给你们，请认真贯彻落实。",
                    link:"http://www.gov.cn/zhengce/content/2021-11/02/content_5648192.htm"
                },
                {      
                    img:"https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/dynamicImg5.jpeg",
                    title:"国务院印发《关于推动现代职业教育高质量发展的意见》",
                    district:"新华社北京10月12日电 近日，中共中央办公厅、国务院办公厅印发了《关于推动现代职业教育高质量发展的意见》，并发出通知，要求各地区各部门结合实际认真贯彻落实。《关于推动现代职业教育高质量发展的意见》主要内容如下。",
                    link:"http://www.gov.cn/zhengce/2021-10/12/content_5642120.htm"
                },
            ],
            courseTotal: 0,
        }
    },
    
    methods: {
        // 分页
        handleCurrentChange(){

        },
    }
}
</script>

<style lang="scss" scoped>
    ::v-deep {
        .el-pagination.is-background .el-pager li:not(.disabled).active {
            background-color: #EA5A41;
        }
        .el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li {
            background-color: #FFFFFF;
            border: 1px solid #d9d9d9;
            box-sizing: content-box;
        }
        .el-pager li {
            padding: 0;
        }
    }

    @media screen and (max-width: 1280px) {
        .content {
            width: 100%;
            min-width: 1200px;
            min-height: calc(100vh - 366px);
            background: #FAFAFA;
            box-sizing: border-box;
            overflow: hidden;
            color: #333333;
            padding-bottom: 60px;

            div {
                box-sizing: border-box;
            }

            .tabbarBox {
                width: 1000px;
                //height: 215px;
                margin: 20px auto;

                .titleBox {
                    width: 1000px;
                    height: 35px;
                    // background: linear-gradient(135deg, rgba(255, 187, 141, .3), rgba(255, 74, 51, .3));
                    padding-left: 12px;
                    display: flex;
                    align-items: center;

                    .title {
                        height: 19px;
                        line-height: 19px;
                        padding-left: 10px;
                        border-left: 4px solid #EA5A41;
                        font-size: 18px;
                        font-weight: bold;
                        color: #333333;
                    }
                }
                .lineBox {
                    padding: 0 12px;
                    height: 1px;
                    background: #FFFFFF;
                    .line {
                        width: 100%;
                        height: 100%;
                        background: #f3f3f3;
                    }
                }
                .box-classifyBox {
                    background: #FFFFFF;
                    width: 1000px;
                    padding: 12px 12px 24px;
                    display: flex;

                    .classifyBox-left {
                        font-size: 14px;
                        flex-shrink: 0;
                        margin-top: 16px;
                    }

                    .classifyBox-right {

                        .classifyItem {
                            padding: 7px 13px;
                            float: left;
                            margin: 10px 0 0 12px;
                            font-size: 14px;
                            border-radius: 30px;
                            cursor: pointer;
                        }
                        .classifyItem:hover {
                            color: #EA5A41;
                        }
                        .classifyItem-active {
                            background: #EA5A41;
                            color: white;
                        }
                        .classifyItem-active:hover {
                            color: white;
                        }
                    }
                }
            }
            .mainListBox {
                padding-bottom: 30px;
                width: 1000px;
                margin: auto;
                display: grid;
                grid-template-columns: repeat(5, 193px);
                grid-gap: 24px 10px;

                .card {
                    width: 190px;
                    background: #FFFFFF;
                    border: 1px solid #F2F2F2;
                    flex-shrink: 0;

                    img {
                        width: 100%;
                        height: 118px;
                    }
                    .courseName {
                        padding: 0 10px;
                        font-size: 14px;
                        font-weight: bold;
                        margin-top: 12px;
                    }
                    .bottomInfo {
                        padding: 10px;
                        margin-top: 20px;
                        display: flex;
                        justify-content: space-between;
                        font-size: 9px;
                        color: #999999;
                    }
                }
            }
            .paginationBox {
                width: 1000px;
                margin: auto;
                display: flex;
                justify-content: space-between;
                font-size: 12px;
                color: rgba(0, 0, 0, 0.65);
            }
        }
    }

    @media screen and (min-width: 1280px) {
        .content {
            width: 100%;
            min-width: 1200px;
            min-height: calc(100vh - 366px);
            background: #FAFAFA;
            box-sizing: border-box;
            overflow: hidden;
            color: #333333;
            padding-bottom: 60px;

            div {
                box-sizing: border-box;
            }

            .tabbarBox {
                width: 1200px;
                //height: 215px;
                margin: 20px auto;

                .titleBox {
                    width: 1200px;
                    height: 35px;
                    // background: linear-gradient(135deg, rgba(255, 187, 141, .3), rgba(255, 74, 51, .3));
                    padding-left: 12px;
                    display: flex;
                    align-items: center;

                    .title {
                        height: 19px;
                        line-height: 19px;
                        padding-left: 10px;
                        border-left: 4px solid #EA5A41;
                        font-size: 18px;
                        font-weight: bold;
                        color: #333333;
                    }
                }
                .lineBox {
                    padding: 0 12px;
                    height: 1px;
                    background: #FFFFFF;
                    .line {
                        width: 100%;
                        height: 100%;
                        background: #f3f3f3;
                    }
                }
                .box-classifyBox {
                    background: #FFFFFF;
                    width: 1200px;
                    padding: 12px 12px 24px;
                    display: flex;

                    .classifyBox-left {
                        font-size: 14px;
                        flex-shrink: 0;
                        margin-top: 16px;
                    }

                    .classifyBox-right {

                        .classifyItem {
                            padding: 7px 13px;
                            float: left;
                            margin: 10px 0 0 12px;
                            font-size: 14px;
                            border-radius: 30px;
                            cursor: pointer;
                        }
                        .classifyItem:hover {
                            color: #EA5A41;
                        }
                        .classifyItem-active {
                            background: #EA5A41;
                            color: white;
                        }
                        .classifyItem-active:hover {
                            color: white;
                        }
                    }
                }
            }
            .mainListBox {
                padding-bottom: 30px;
                width: 1200px;
                margin: auto;
                display: grid;
                grid-template-columns: repeat(5, 233px);
                grid-gap: 24px 10px;

                .card {
                    width: 230px;
                    background: #FFFFFF;
                    border: 1px solid #F2F2F2;
                    flex-shrink: 0;

                    img {
                        width: 100%;
                        height: 131px;
                    }
                    .courseName {
                        padding: 0 10px;
                        font-size: 14px;
                        font-weight: bold;
                        margin-top: 12px;
                    }
                    .bottomInfo {
                        padding: 10px;
                        margin-top: 20px;
                        display: flex;
                        justify-content: space-between;
                        font-size: 11px;
                        color: #999999;
                    }
                }
            }
            .paginationBox {
                width: 1200px;
                margin: auto;
                display: flex;
                justify-content: space-between;
                font-size: 12px;
                color: rgba(0, 0, 0, 0.65);
            }
        }
    }
</style>